.categories-page-header {
    display: flex;
    align-items: center;
    margin: auto;
    gap: 200px;
    justify-content: center;
    margin-bottom: 40px;
}

.categories-page-header img {
    width: 722px;
    height: 352px;
    object-fit: cover;
    object-position: bottom;
    background-color: var(--cinza-principal);
}

.no-products {
    text-align: center;
    padding: 24px 24px;
}

.search-btn {
    text-align: center;
    margin: 24px auto;
    color: var(--cor-principal);
}

.btnNoProducts {
    margin: 12px 0px;
    background-color: var(--cor-principal);
    color: white;
    border-radius: 8px;
    padding: 8px 0px;
    width: 200px;
}

.no-products h1 {
    color: var(--cor-principal);
}

.categories-page-about {
    width: 355px;
}

.categories-page-about h2 {
    font-size: 22px;
    text-transform: uppercase;
}

.categories-page-about p {
    margin: 16px 0px;
    font-size: 14px;
}

.categories-page-btn {
    font-size: 14px;
}

.category-filter-content {
    width: 339px;
}

.category-filter__body {
    margin: 24px 0px;
}
 
.category-filter-wrapper p {
    font-size: 12px;
}

.category-filter-wrapper h2 {
    font-size: 14px;
    font-weight: 500;
}

.category-first-options {
    margin-bottom: 58px;
}

.category-orderby {
    margin-top: 38px;
}

.radioGroup {
    padding: 16px 0px;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.categor-bottom {
    display: flex;
    align-items: center;
}

.category-body-content {
    display: flex;
    justify-content: space-between;
    margin: auto;
    width: 1300px;
}

.category-products-content {
    width: 100%;
}

.category-products__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.category-products__content {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 32px;
    flex-wrap: wrap;
}

.category-left-products p {
    font-size: 14px;
    color: #000000;
    font-weight: 300;
}

.category-products__header {
    margin-bottom: 36px;
}

.category-products__bottom {
    margin: auto;
    justify-content: center;
    display: flex;
    align-items: center;

}

.mobile-products-show {
    display: none;
}

@media screen and (max-width: 900px) {
    .categories-page-about {
        display: block;
    }

    .desktop-products-show {
        display: none;
    }

    .mobile-products-show {
        display: block;
    }

    .mobile-products-show_inside {
        width: 90%;
        margin: auto;
    }

    .categories-page-header {
        display: block;
    }

    .categories-page-header img {
        width: 100%;
        height: auto;
        object-fit: contain;
    }

    .category-filter-content {
        display: block;
        margin: auto;
    }

    .category-body-content {
        width: 100%;
        flex-direction: column;
    }

    .body-category-mobile {
        padding: 12px 12px;
        border-top: 1px solid lightgray;
    }

    .mobile-filter-button {
        display: flex;
    }

    .categories-page-header {
        gap: 0;
        margin: auto;
    }

    .categories-page-image {
        width: 100%;
    }

    .categories-page-about h2 {
        font-weight: 700;
    }

    .categories-page-about {
        padding: 16px 12px;
    }

    .category-products-content {
        padding: 0px 12px;
    }
}