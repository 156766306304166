.loader-component {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
}

.loader-backdrop {
    background-color: rgba(128, 128, 128, 0.241);
    width: 100%;
    height: 100vh;
    position: fixed;
    z-index: 50;
    top: 0;
}

.loader-inside {
    position: fixed;
    top: 0;
    left: 0;
    margin: auto;
    z-index: 60;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loader-content {
    animation: loader 0.5s infinite;
    color: var(--cor-principal) !important;
    scale: 1.5;
}

@keyframes loader {
    0% {
        transform: rotateZ(0deg);
    }

    100% {
        transform: rotateZ(360deg);
    }
}