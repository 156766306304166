.carousel-wrapper {
  max-height: 100vh;
  height: 100vh;
  width: 100%;
  margin: auto;
  margin-top: -185px;
  position: relative;
  background-color: var(--cinza-principal);
  background-repeat: repeat;
  background: linear-gradient(180deg, rgba(217, 217, 217, 0) 0%, rgba(35, 35, 35, 0) 64%, rgba(0, 0, 0, 0.7) 100%);
}

.carousel-wrapper .carrousel-text {
  position: absolute;
  bottom: 0;
  color: white;
  left: 0;
  right: 0;
  bottom: 25px;
  text-align: center;
  width: 700px;
  margin: auto;
  justify-content: center;
  text-transform: uppercase;
}

.carrousel-text p {
  font-size: 12px;
  margin: 12px 0px;
}

.carrousel-text button {
  padding: 10px 20px;
  border: 1px solid white;
}

.carousel-wrapper img {
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  height: 100%;
  object-fit: cover;
}

@media screen and (max-width: 900px) {
  .carousel-wrapper img {
    object-fit: cover;
    max-height: 550px;
  }

  .carousel-wrapper {
    width: 100%;
    height: 550px;
    margin-top: -0px;
  }

  .carousel-wrapper .carrousel-text {
    position: absolute;
    color: white;
    left: 0;
    right: 0;
    bottom: 25px;
    text-align: center;
    width: 100%;
    margin: auto;
    justify-content: center;
    text-transform: uppercase;
  }
}