.checkout-page-wrapper {
  padding: 0px 22px;
  margin-top: -90px;
}

.checkout-header h1 {
  font-size: 28px;
}

.checkout-grid-component {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.checkout-grid__inside_left {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.box-checkout {
  padding: 10px 8px;
  border: 1px solid #d8d8d8;
  width: 1000px;
}

.box-checkout-right {
  padding: 20px;
  border: 1px solid #d8d8d8;
  width: 390px;
  height: 610px;
}

.item-data-list-prices-principal {
  color: var(--cor-principal);
  font-weight: 600;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 16px 0px;
}

.item-data-list-prices {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 16px 0px;
  border-bottom: 1px solid #ecf0f1;
}

.chakra-data-list__itemLabel {
  width: 50%;
}

.chakra-data-list__itemValue {
  width: 50%;
  text-align: right;
}

.finalize-btn {
  width: 100%;
  color: white;
  background-color: #000000;
  border: 1px solid #000000;
  padding: 16px;
  display: flex;
  align-items: center;
  gap: 8px;
  text-align: center;
  justify-content: center;
}

.items-component-checkout label span {
  color: var(--cor-principal);
  width: 100% !important;
}

.typeAddressSelected {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 16px;
  width: 100%;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.addressItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  width: 100% !important;
  border-radius: 8px;
  border: 1px solid #ddd;
  background-color: #fff;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
}

.addressItem:hover {
  background-color: #f1f1f1;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.addressItem__insideleft p:first-child {
  font-weight: bold;
  font-size: 14px;
  color: #333;
}

.addressItem__insideleft p:last-child {
  font-size: 12px;
  color: #777;
}

.addressItem__insideright p {
  font-weight: bold;
  font-size: 14px;
  color: #333;
}

input[type="checkbox"]:checked::after {
  content: '\2713';
  font-size: 14px;
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
label#title {
  font-size: 18px;
}

.title-box-checkout {
  margin-bottom: 20px;
}

.continue_buyingBtn {
  width: 100%;
  color: var(--cor-principal);
  border: 1px solid var(--cor-principal);
  background-color: transparent;
  padding: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.item-step .chakra-steps__title {
  color: black !important;
}

.actions-buttons {
  padding: 10px 0px;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 10px;
}

.checkout-box-right-header {
  border-bottom: 1px solid #d8d8d8;
  padding-bottom: 10px;
}

.checkout-box-right-header h1 {
  font-size: 18px;
}

.item-cart-box {
  border: 1px solid #d8d8d8;
}

.item-cart-box img {
  width: 100px;
  height: 100px;
  object-fit: cover;
}

.item-cart-box__inside {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  padding: 0px 8px;
}

.item-info {
  display: flex;
  align-items: center;
  gap: 8px;
}

.item-middle {
  display: flex;
  align-items: center;
  gap: 8px;
}

.items-component-checkout {
  display: flex;
  gap: 8px;
  flex-direction: column;
}
.step2 .box-checkout__header {
  display: flex;
  align-items: center;
  gap: 10px;
}

.box-checkout__header #numberRef {
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  margin: auto;
  justify-content: center;
  border-radius: 100px;
  background-color: var(--cor-principal);
  color: white;
}

.typeEmailCheckout {
}

.section-payment-inside img {
  width: 150px;
}

@media screen and (max-width: 900px) {
  .box-checkout {
    width: 100%;
  }

  .checkout-grid-component {
    flex-direction: column;
  }

  .checkout-page-wrapper {
    margin-top: 00px;
  }

  .box-checkout-right {
    width: 100%;
  }
}
