.product-card-wrapper {
  position: relative;
  text-align: center;
}

#feed {
  width: 297px;
}

#grid {
  width: 225px;
}

#feed img {
  height: 350px;
}

#grid img {
  height: 225px;
}

.product-card-wrapper img {
  width: 100%;
  object-fit: contain;
  background-color: var(--cinza-principal);
}

.favorite-button-container {
  position: absolute;
  top: 5px;
  right: 10px;
  fill: white;
  background-color: white;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  border-radius: 50px;
  margin: auto;
  justify-content: center;
}

.product-title {
  font-size: 14px;
  font-weight: 600;
}

.product-title-container {
  height: 38px;
}

.product-subtitle {
  font-size: 12px;
  margin: 16px 0px;
}

.product-card-footer {
  padding-top: 30px;
}

.btnSection {
  text-align: center;
  margin: auto;
  width: 100%;
  align-items: center;
  justify-content: center;
  height: 100px;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.btnSection select {
  border: 1px solid black;
  padding: 4px 0px;
  text-align: center;
  outline: none;
  font-size: 12px;
  width: 80%;
}

.btnSection button {
  text-align: center;
  background-color: black;
  color: white;
  width: 80%;
  padding: 8px 0px;
  font-size: 12px;
  font-weight: bold;
}

@media screen and (max-width: 900px) {
  .product-card-wrapper {
    width: 157px;
  }

  #feed {
    width: 157px;
  }

  #grid {
    width: 100px;
  }

  #feed img {
    height: 157px;
  }

  #grid img {
    height: 100px;
  }

  .product-card-wrapper img {
    height: 157px;
  }

  .product-title {
    font-size: 14px;
    font-weight: 600;
  }

  .product-title-container {
    height: 68px;
  }
}