.showcase-component {
    width: 1200px;
    margin: auto;
    padding: 32px 0px;
}

.showcase-inside {
    display: flex;
    align-items: center;
    gap: 12px;
    text-align: center;
    flex-wrap: nowrap;
}

.showcase-inside-full {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 12px;
    margin: auto;
    justify-content: center;
}

.showcase-box {
    width: 615px;
    height: 500px;
}

.showcase-box-full {
    width: 100%;
    height: auto;

}


.showcase-box img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.showcase-box-full img {
    width: 1200px;
    height: 500px;
    object-fit: cover;
}

@media screen and (max-width: 900px) {
    .showcase-inside-full {
        display: block;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .showcase-box-full {
        width: 100%;
        margin-bottom: 20px;
    }

    .showcase-box-full img {
        width: 100%;
        height: 500px;
        object-fit: cover;
    }

    .showcase-box {
        width: 95%;
        margin: auto;
        margin-bottom: 80px;
    }

    .showcase-inside {
        display: flex;
        align-items: center;
        gap: 24px;
        text-align: center;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .showcase-component {
        width: 100%;
        padding: 32px 0px;
    }

    .descriptionshowcase {
        padding: 10px;
    }
}